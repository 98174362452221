@import "../../../variables";
@import "../../../mixins";
@import "../../../helperClasses";


.kii-carousel {
    position: relative;
    .kii-content-slide {
        &.product-slide {
            width: 293px !important;
            @include media-breakpoint-up(sm) {
                width: 273px !important;
            }
            @include media-breakpoint-up(md) {
                width: 247px !important;
            }
            @include media-breakpoint-up(lg) {
                width: 314px !important;
            }
            @include media-breakpoint-up(xl) {
                width: 255px !important;
            }
            @include media-breakpoint-up(xxl) {
                width: 300px !important;
            }
            .slider-image-block {
                img {
                    object-fit: cover;
                    height: 266px;
                    @include media-breakpoint-up(sm) {
                        height: 240px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 215px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 279px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 223px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 270px;
                    }
                }
            }
            .image-container,
            .article-block {
                img {
                    object-fit: cover;
                    height: 266px;
                    @include media-breakpoint-up(sm) {
                        height: 240px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 215px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 279px;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 223px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 270px;
                    }
                }
            }
        }
        &.content-large-image-tile-block {
            flex: 0 0 auto;
            width: 80%;
            @include media-breakpoint-up(sm) {
                width: 364px;
            }
            @include media-breakpoint-up(md) {
                width: 244px;
            }
            @include media-breakpoint-up(lg) {
                width: 314px;
            }
            @include media-breakpoint-up(xl) {
                width: 340px;
            }
            @include media-breakpoint-up(xxl) {
                width: 404px;
            }
        }
        &.content-small-image-tile-block {
            flex: 0 0 auto;
            width: 197px;
            @include media-breakpoint-up(sm) {
                width: 182px;
            }
            @include media-breakpoint-up(md) {
                width: 122px;
            }
            @include media-breakpoint-up(lg) {
                width: 157px;
            }
            @include media-breakpoint-up(xl) {
                width: 170px;
            }
            @include media-breakpoint-up(xxl) {
                width: 200px;
            }
        }
        &.content-large-image-tile-block,
        &.content-small-image-tile-block {
            .slider-image-block,
            .image-container,
            .article-block {
                img {
                    object-fit: cover;
                    height: 193px;
                    @include media-breakpoint-up(sm) {
                        height: 221px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 171px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 225px;
                    }
                    @include media-breakpoint-up(xxl) {
                        height: 230px;
                    }
                }
            }
        }
        .article-block {
            padding: 0;
        }
    }
}

.main-slider {
    .slick-list, .slick-track {
        display: flex;
    }
    .vimeo-video-frame {
        max-height: 334px;
        @include media-breakpoint-up(sm) {
            max-height: 256px;
        }
        @include media-breakpoint-up(md) {
            max-height: 354px;
        }
        @include media-breakpoint-up(lg) {
            max-height: 449px;
        }
        @include media-breakpoint-up(xl) {
            max-height: 545px;
        }
        @include media-breakpoint-up(xxl) {
            max-height: 750px;
        }
        
    }
}
.slick-track {
    margin-left: 0;
}

.carousel-title {
    * {
        color: $brand-secondary;
    }
    h3, h4 {
        font-weight: $font-weight-semibold;
        @include media-breakpoint-only(lg) {
            font-size: 22px;
            line-height: 30px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 20px;
            line-height: 28px;
        }
    }
    &[class*="font-size--"] {
        * {
            font-size: inherit;
        }
    }
    .custom-text-color {
        * {
            color: inherit;
        }
    }
}

.half-and-half-slider {
    .slick-slide {
        height: auto;
    }
    .carousel-static-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-top: -50px;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
        .carousel-title {
            padding-top: 0;
        }
    }
    .images-as-indicators {
        ul {
            display: flex;
            margin-top: 20px;
            flex-wrap: wrap;
            li {
                padding: 0;
                margin-right: 5px;
                cursor: pointer;
                margin-bottom: 5px;
                img {
                    width: 117px;
                    height: 70px;
                    border: 1px solid $default-bg;
                    object-fit: contain;
                }
                &:hover img, &.active img {
                    border-color: $brand-primary;
                }
                .img-title {
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
    .img-info-overlay-parent > img {
        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }
    }
    .kii-carousel-controls {
        position: static;
    }
}
